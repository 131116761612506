<template>
<div class="contact">
  <p>Coming Soon</p>
</div>
</template>

<style scoped>
.contact{
  padding-top: 12vh;
}
</style>
