<template>
  <div class="about">
    <p id="about-p">
      I am a photographer located in Victoria, British Columbia, Canada with my primary medium being film photography. 
      My main subjects are landscapes and elements found in nature.

      Other hobbies include 3D art, drawing, playing the guitar, and programming.
    </p>
  </div>
</template>

<style>
#about-p {
  text-align: left;
  margin-left: 2vw;
  padding-top: 12vh;
}

</style>
